import already_login_src from './alreadyLogin.svg';
import apple_social_icon_src from './apple-social-icon.svg';
import facebook_social_icon_src from './facebook-social-icon.svg';
import google_logo_src from './google_logo.png';
import google_social_icon_src from './google-social-icon.svg';
import icn_email_src from './icn_email.svg';
import mobile_icon_src from './icn_mobile.png';
import icn_mobile_circle_src from './icn_mobile_circle.svg';
import user_icon_src from './icn_user.png';
import kakao_social_icon_src from './kakao-social-icon.svg';
import kakaotalk_logo_src from './kakaotalk_logo.png';
import mail_logo_src from './mail_log.png';
import phone_icon_src from './phone.svg';
import toss_social_icon_src from './toss-social-icon.png';
export const apple_social_icon = apple_social_icon_src;
export const kakao_social_icon = kakao_social_icon_src;
export const google_social_icon = google_social_icon_src;
export const facebook_social_icon = facebook_social_icon_src;
export const kakaotalk_logo = kakaotalk_logo_src;
export const google_logo = google_logo_src;
export const already_login = already_login_src;
export const mail_logo = mail_logo_src;
export const mobile_icon = mobile_icon_src;
export const user_icon = user_icon_src;
export const phone_icon = phone_icon_src;
export const icn_email = icn_email_src;
export const icn_mobile_circle = icn_mobile_circle_src;
export const toss_social_icon = toss_social_icon_src;
